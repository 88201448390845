import React from "react";
// Master
import csharp from "../../assets/images/technos/csharp.webp";
import js from "../../assets/images/technos/javascript.webp";
import ts from "../../assets/images/technos/typescript.svg";
import dotnet from "../../assets/images/technos/dotnet.webp";
import react from "../../assets/images/technos/react.webp";
import symfony from "../../assets/images/technos/symfony.webp";
import git from "../../assets/images/technos/git.webp";
// Other
import tailwind from "../../assets/images/technos/tailwind.webp";
import php from "../../assets/images/technos/php.webp";
import figma from "../../assets/images/technos/figma.webp";
import mysql from "../../assets/images/technos/mysql.webp";
import postgresql from "../../assets/images/technos/postgresql.webp";
import sqlserver from "../../assets/images/technos/sqlserver.webp";
import jira from "../../assets/images/technos/jira.webp";
import gitlab from "../../assets/images/technos/gitlab.webp";
import kubernetes from "../../assets/images/technos/kubernetes.webp";
import docker from "../../assets/images/technos/docker.webp";
import dockerCompose from "../../assets/images/technos/docker-compose.webp";
import nextjs from "../../assets/images/technos/nextjs.webp";
// Work environments
import apple from "../../assets/images/technos/apple.svg";
import windows from "../../assets/images/technos/windows.webp";
import linux from "../../assets/images/technos/linux.webp";

const Technologies = () => {
  return (
    <section
      id="technologies"
      className="technologies min-h-screen max-w-[1280px] text-center"
    >
      <h5 className="text-2xl text-center mt-20 mb-10">Technologies</h5>
      <h2 className="text-4xl text-center mb-14 font-oswald">
        Mes connaissances
      </h2>

      <div>
        <h3 className="mt-12 mb-6 text-xl font-oswald tracking-wider">
          Je maitrise
        </h3>
        <ul className="flex flex-row flex-wrap justify-center items-center gap-12 mb-10">
          <li>
            <img src={dotnet} alt="dotnet logo" className="w-auto h-12" />
          </li>
          <li>
            <img src={csharp} alt="csharp logo" className="w-auto h-12" />
          </li>
          <li>
            <img src={js} alt="javascript logo" className="w-auto h-12" />
          </li>
          <li>
            <img src={ts} alt="typescript logo" className="w-auto h-12" />
          </li>
          <li>
            <img src={react} alt="react logo" className="w-auto h-12" />
          </li>
          <li>
            <img src={symfony} alt="symfony logo" className="w-auto h-12" />
          </li>
          <li>
            <img src={git} alt="git logo" className="w-auto h-12" />
          </li>
        </ul>

        <span className="border-[1px] border-solid border-copper text-copper rounded px-4 py-2">
          Méthodes agiles
        </span>
      </div>

      <div>
        <h3 className="mt-12 mb-6 text-xl font-oswald tracking-wider">
          Autres
        </h3>
        <ul className="flex flex-row flex-wrap justify-center items-center gap-14 mb-8 max-w-[610px]">
          <li>
            <img
              src={tailwind}
              alt="tailwind logo"
              className="w-auto h-10 grayscale hover:grayscale-0 ease-in-out duration-500"
            />
          </li>
          <li>
            <img
              src={mysql}
              alt="mysql logo"
              className="w-auto h-10 grayscale hover:grayscale-0 ease-in-out duration-500"
            />
          </li>
          <li>
            <img
              src={postgresql}
              alt="postgresql logo"
              className="w-auto h-10 grayscale hover:grayscale-0 ease-in-out duration-500"
            />
          </li>
          <li>
            <img
              src={sqlserver}
              alt="sqlserver logo"
              className="w-auto h-10 grayscale hover:grayscale-0 ease-in-out duration-500"
            />
          </li>
          <li>
            <img
              src={figma}
              alt="figma logo"
              className="w-auto h-10 grayscale hover:grayscale-0 ease-in-out duration-500"
            />
          </li>
          <li>
            <img
              src={jira}
              alt="jira logo"
              className="w-auto h-10 grayscale hover:grayscale-0 ease-in-out duration-500"
            />
          </li>
          <li>
            <img
              src={gitlab}
              alt="gitlab logo"
              className="w-auto h-10 grayscale hover:grayscale-0 ease-in-out duration-500"
            />
          </li>
          <li>
            <img
              src={kubernetes}
              alt="kubernetes logo"
              className="w-auto h-10 grayscale hover:grayscale-0 ease-in-out duration-500"
            />
          </li>
          <li>
            <img
              src={docker}
              alt="docker logo"
              className="w-auto h-10 grayscale hover:grayscale-0 ease-in-out duration-500"
            />
          </li>
          <li>
            <img
              src={dockerCompose}
              alt="docker-compose logo"
              className="w-auto h-10 grayscale hover:grayscale-0 ease-in-out duration-500"
            />
          </li>
          <li>
            <img
              src={nextjs}
              alt="nextjs logo"
              className="w-auto h-10 contrast-50 hover:contrast-100 ease-in-out duration-500"
            />
          </li>
        </ul>
      </div>

      <div>
        <h3 className="mt-12 mb-6 text-xl font-oswald tracking-wider">
          Environnements
        </h3>
        <ul className="flex flex-row flex-wrap justify-center items-center gap-14 mt-8">
          <li>
            <img
              src={apple}
              alt="apple logo"
              className="w-auto h-12 contrast-50 hover:contrast-100 ease-in-out duration-500"
            />
          </li>
          <li>
            <img
              src={windows}
              alt="windows logo"
              className="w-auto h-12 grayscale hover:grayscale-0 ease-in-out duration-500"
            />
          </li>
          <li>
            <img
              src={linux}
              alt="linux logo"
              className="w-auto h-12 grayscale hover:grayscale-0 ease-in-out duration-500"
            />
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Technologies;
