import React from "react";
import { FaAward, FaCertificate } from "react-icons/fa";
import { BsPatchCheckFill } from "react-icons/bs";
import { VscFolderLibrary } from "react-icons/vsc";
import me from "../../assets/me_square.png";

const About = () => {
  return (
    <section id="about" className="min-h-screen max-w-[1280px] text-center">
      <h5 className="text-2xl text-center mt-20 mb-8">Me connaître</h5>
      <h2 className="text-4xl text-center mb-16 font-oswald">
        À propos de moi
      </h2>

      <div className="container flex justify-between gap-20 flex-col lg:flex-row">
        <div className="about-me hidden md:flex lg:w-4/12  aspect-square rounded-3xl bg-gradient-to-bl from-bone to-dark-lava">
          <div className="about-me-image  rounded-3xl overflow-hidden rotate-12 hover:rotate-0 ease-in-out duration-300">
            <img
              className="w-[28rem] max-w-[28rem]"
              src={me}
              alt="Tony Pedrero"
            />
          </div>
        </div>

        <div className="about-content sm:w-full lg:w-7/12 flex flex-col justify-evenly">
          <div className="about-cards flex flex-col gap-4 md:gap-O md:flex-row items-center justify-between text-center">
            <div className="about-card flex flex-col items-center justify-between w-[150px] h-[150px] md:w-[170px] md:h-[170px] text-white rounded-2xl p-8 border-[1px] border-solid border-copper bg-copper hover:bg-copper-dark hover:border-copper-dark ease-in-out duration-500">
              <FaAward className="about-icon text-2xl" />
              <h5 className="text-base font-semibold">Expérience</h5>
              <small className="text-sm">2 ans d'alternance</small>
            </div>
            <div className="about-card flex flex-col items-center justify-between w-[150px] h-[150px] md:w-[170px] md:h-[170px] text-white rounded-2xl p-8 border-[1px] border-solid border-copper bg-copper hover:bg-copper-dark hover:border-copper-dark ease-in-out duration-500">
              <BsPatchCheckFill className="about-icon text-2xl" />
              <h5 className="text-base font-semibold">Diplôme</h5>
              <small className="text-sm">Bac+5 - Expert dev web</small>
            </div>
            <div className="about-card flex flex-col items-center justify-between w-[150px] h-[150px] md:w-[170px] md:h-[170px] text-white rounded-2xl p-8 border-[1px] border-solid border-copper bg-copper hover:bg-copper-dark hover:border-copper-dark ease-in-out duration-500">
              <VscFolderLibrary className="about-icon text-2xl" />
              <h5 className="text-base font-semibold">Projets</h5>
              <small className="text-sm">10+ projets finis</small>
            </div>
          </div>

          <p className="m-8 md:m-0 md:mt-8 text-left">
            Je suis Tony, développeur web full stack. Je suis piqué de web
            depuis de nombreuses années maintenant. Aujourd'hui, je suis diplômé
            d'une license professionnelle en développement web et prochainement
            du master expert en développement web réalisé en alternance chez CIS
            Valley.
            <br />
            En dehors de mon activité pro, je suis très actif, amateur de sports
            et de nature. J'aime me dépenser et découvrir le monde. Je suis
            également un amateur de cuisine dévoué et fervent consomateur de
            musique.
          </p>

          <a
            href="#contact"
            className="btn btn-primary m-8 md:m-0 md:mt-8 w-[187px] border-solid rounded-lg bg-copper border-copper text-white border-[1px] px-6 py-3 hover:bg-copper-dark hover:border-copper-dark ease-in-out duration-500"
          >
            Entrons en contact
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
