import React from "react";
import { FaAward, FaCertificate } from "react-icons/fa";
import { BsPatchCheckFill } from "react-icons/bs";
import { VscFolderLibrary } from "react-icons/vsc";
import me from "../../assets/me_square.png";

import { BsLinkedin } from "react-icons/bs";
import { AiOutlineGitlab } from "react-icons/ai";

const Footer = () => {
  return (
    <footer id="footer" className="h-20 w-full flex justify-center bg-khaki">
      <div className="max-w-[1280px] w-4/5 flex flex-row justify-between items-center">
        <p>© 2022 Tony Pedrero. Tous droits réservés.</p>

        <div className="footer-socials flex flex-row items-center gap-3">
          <a
            href="https://www.linkedin.com/in/tony-pedrero-97916216a/"
            target="_blank"
            className="text-copper text-3xl"
          >
            <BsLinkedin />
          </a>
          <a
            href="https://gitlab.com/ToOnyto"
            target="_blank"
            className="text-copper text-3xl"
          >
            <AiOutlineGitlab />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
