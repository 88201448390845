import React from "react";
import { BsLinkedin } from "react-icons/bs";
import { AiOutlineGitlab } from "react-icons/ai";

const HeaderSocials = () => {
  return (
    <div className='header-socials flex flex-col items-center gap-3 absolute left-0 bottom-12 after:content-["_"] after:w-[1px] after:h-8 after:bg-copper'>
      <a
        href="https://www.linkedin.com/in/tony-pedrero-97916216a/"
        target="_blank"
        className="text-copper text-3xl"
      >
        <BsLinkedin />
      </a>
      <a
        href="https://gitlab.com/ToOnyto"
        target="_blank"
        className="text-copper text-3xl"
      >
        <AiOutlineGitlab />
      </a>
    </div>
  );
};

export default HeaderSocials;
