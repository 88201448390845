import React, { useRef } from "react";
import emailjs from "emailjs-com";
import { MdOutlineEmail } from "react-icons/md";
import { BsLinkedin } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const form = useRef<HTMLFormElement>(null);

  const sendEmail = (e: any) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_liv6n6j",
        "template_b6rxsoo",
        "#contact-form",
        "A17OaeQbWh7rO1FOj"
      )
      .then(
        (result) => {
          toast.success("Votre message à bien été envoyé.");
        },
        (error) => {
          toast.error("Une erreur est survenue.");
        }
      );
  };

  return (
    <section
      id="contact"
      className="contact min-h-[95vh] w-4/5 flex flex-col max-w-[1280px] text-center"
    >
      <h5 className="text-2xl text-center mt-20 mb-10">Entrons en contact</h5>
      <h2 className="text-4xl text-center mb-14 font-oswald">Me contacter</h2>

      <div className="container contact-container flex md:gap-24 gap-8 flex-col lg:flex-row">
        <div className="contact-options w-full lg:w-4/12 flex flex-col gap-8">
          <div className="contact-option about-card flex flex-col items-center justify-between md:h-[190px] h-120px rounded-2xl md:py-8 py-4 px-8 text-white border-[1px] border-solid border-copper bg-copper hover:bg-copper-dark hover:border-copper-dark ease-in-out duration-500">
            <MdOutlineEmail className="text-2xl" />
            <h4 className="text-xl font-bold font-oswald">Email</h4>
            <h5 className="text-sm">pedrero.tony@yahoo.com</h5>
            <a
              href="mailto:pedrero.tony@yahoo.com"
              target="_blank"
              className="pt-2 hover:text-bonne-light hover:underline"
            >
              Envoyer un message
            </a>
          </div>
          <div className="contact-option about-card flex flex-col items-center justify-between md:h-[190px] h-120px rounded-2xl md:py-8 py-4 px-8 text-white border-[1px] border-solid border-copper bg-copper hover:bg-copper-dark hover:border-copper-dark ease-in-out duration-500">
            <BsLinkedin />
            <h4 className="text-xl font-bold font-oswald">LinkedIn</h4>
            <h5>Tony Pedrero</h5>
            <a
              href="https://www.linkedin.com/in/tony-pedrero-97916216a/"
              target="_blank"
              className="pt-2 hover:text-bonne-light hover:underline"
            >
              Envoyer un message
            </a>
          </div>
        </div>
        <ToastContainer />
        <form
          id="contact-form"
          ref={form}
          onSubmit={sendEmail}
          className="flex flex-col w-full md:w-8/12"
        >
          <input
            type="text"
            name="name"
            placeholder="Votre nom complet *"
            required
            className="mb-6 px-4 py-3 placeholder:text-[#90543C] placeholder:opacity-70 border-b-2 border-solid border-copper bg-bonne-dark rounded-lg"
          />
          <input
            type="email"
            name="email"
            placeholder="Votre email *"
            required
            className="mb-6 px-4 py-3 placeholder:text-[#90543C] placeholder:opacity-70 border-b-2 border-solid border-copper bg-bonne-dark rounded-lg"
          />
          <input
            type="phone"
            name="phone"
            placeholder="Votre téléphone"
            className="mb-6 px-4 py-3 placeholder:text-[#90543C] placeholder:opacity-70 border-b-2 border-solid border-copper bg-bonne-dark rounded-lg"
          />
          <textarea
            name="message"
            cols={30}
            rows={7}
            placeholder="Votre message *"
            required
            className="mb-6 px-4 py-3 placeholder:text-[#90543C] placeholder:opacity-70 border-b-2 border-solid border-copper bg-bonne-dark rounded-lg"
          ></textarea>
          <button
            type="submit"
            className="btn btn-primary self-end mt-2 mb-4 w-[187px] border-solid rounded-lg bg-copper border-copper text-white border-[1px] px-6 py-3 hover:bg-copper-dark hover:border-copper-dark ease-in-out duration-500"
          >
            Envoyer
          </button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
