import React from "react";
import { AiOutlineHome, AiOutlineUser } from "react-icons/ai";
import { BiBook, BiMessageSquareDetail } from "react-icons/bi";
import { GiComputing } from "react-icons/gi";

import "./nav.css";

const Navigation = () => {
  return (
    <nav className="navigation w-max flex px-8 py-3 z-[2] fixed bottom-10 gap-5 rounded-[3rem]">
      <a
        href="#header"
        className="text-white p-2 text-xl hover:text-copper active:text-copper ease-in-out duration-500"
      >
        <AiOutlineHome />
      </a>
      <a
        href="#about"
        className="text-white p-2 text-xl hover:text-copper active:text-copper ease-in-out duration-500"
      >
        <AiOutlineUser />
      </a>
      <a
        href="#technologies"
        className="text-white p-2 text-xl hover:text-copper active:text-copper ease-in-out duration-500"
      >
        <GiComputing />
      </a>
      <a
        href="#projets"
        className="text-white p-2 text-xl hover:text-copper active:text-copper ease-in-out duration-500"
      >
        <BiBook />
      </a>
      <a
        href="#contact"
        className="text-white p-2 text-xl hover:text-copper active:text-copper ease-in-out duration-500"
      >
        <BiMessageSquareDetail />
      </a>
    </nav>
  );
};

export default Navigation;
