import React from "react";
import cv from "../../assets/cv.pdf";

const CTA = () => {
  return (
    <div className="cta flex gap-5 justify-center mt-10">
      <a
        href={cv}
        download
        className="btn border-solid rounded-lg border-[1px] px-6 py-3 border-dark-lava text-dark-lava hover:bg-bonne-dark hover:border-copper hover:text-copper ease-in-out duration-500"
      >
        Voir mon CV
      </a>
      <a
        href="#contact"
        className="btn btn-primary border-solid rounded-lg bg-copper border-copper text-white  border-[1px] px-6 py-3 hover:bg-copper-dark hover:border-copper-dark ease-in-out duration-500"
      >
        Entrons en contact
      </a>
    </div>
  );
};

export default CTA;
