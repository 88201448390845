import React from "react";
import "./header.css";
import CTA from "./CTA";
import HeaderSocials from "./HeaderSocials";
import ME from "../../assets/me.png";

const Header = () => {
  return (
    <header
      id="header"
      className="lg:h-screen md:h-[100vh] h-screen w-4/5 pt-28 overflow-hidden"
    >
      <div className="container header-container text-center h-full relative">
        <h5 className="text-2xl font-lato">Bonjour, je suis</h5>
        <h1 className="text-6xl mb-4 mt-2 font-oswald">Tony Pedrero</h1>
        <h5 className="text-3xl font-lato">Developpeur Web</h5>
        <CTA />

        <HeaderSocials />

        <div className="me bg-gradient-to-bl from-dark-lava to-khaki w-[22rem] h-[30rem] absolute left-[calc(50%-11rem)] mt-16 rounded-[12rem_12rem_0_0] overflow-hidden p-[5rem_1.5rem_1.5rem_1.5rem]">
          <img
            src={ME}
            alt="Moi"
            className="absolute -bottom-12 left-[calc(50%-13rem)] max-w-[40rem] w-[28rem]"
          />
        </div>

        <a
          href="#"
          className="scroll-down absolute -right-9 bottom-20 rotate-90 text-copper"
        >
          Scroll down
        </a>
      </div>
    </header>
  );
};

export default Header;
