import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import HMP from "../../assets/images/projets/HealMePlease.png";
import RE from "../../assets/images/projets/ReverseEats.png";
import Atoute from "../../assets/images/projets/Atoute.png";
import WIMM from "../../assets/images/projets/WIMM.png";

// import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./projets.css";

// import required modules
import { Navigation, Pagination, Autoplay } from "swiper";

const projectData = [
  {
    name: "Heal Me Please",
    abbr: "HMP",
    img_src: { HMP },
    img_alt: "Heal Me Please : Diagnostique médical en ligne.",
    description:
      "Site de diagnostique en ligne par IA, gestion de rendez-vous et suivi de patient.",
    tags: [
      "Next JS",
      "Nest JS",
      "Typescript",
      "Gitlab CI/CD",
      "Kubernetes",
      "Docker",
      "PostgreSQL",
    ],
    url: "",
  },
  {
    name: "Reverse Eats",
    abbr: "RE",
    img_src: { RE },
    img_alt: "Reverse Eats : Prestation culinaire à domicile.",
    description:
      "Application de commande de prestation culinaire. Le restaurant à la maison.",
    tags: ["React Native", "Typescript", "Gitlab CI/CD", "Firebases"],
    url: "",
  },
  {
    name: "Atoute",
    abbr: "Atoute",
    img_src: { Atoute },
    img_alt: "Atoute : Plateforme d'aide au recrutement.",
    description:
      "Site de mise en relation d'entreprises et de particuliers en recherche d'emploi.",
    tags: [
      "React",
      "Typescript",
      "Symfony",
      "MySQL",
      "Elasticsearch",
      "Kibana",
      "Gitlab CI/CD",
    ],
    url: "",
  },
  {
    name: "Where Is My Money",
    abbr: "WIMM",
    img_src: { WIMM },
    img_alt: "Where is my money : Site de gestion de budget.",
    description:
      "Gérer son budget n'est jamais simple. Where is my money permet de visualiser son budget en un instant. Dépensez mieux et économisez avec le système de cagnotte",
    tags: [
      "React",
      "Typescript",
      "Laravel",
      "MySQL",
      "API Plaid",
      "Gitlab CI/CD",
    ],
    url: "",
  },
];

const Projets = () => {
  return (
    <section
      id="projets"
      className="projets min-h-screen max-w-[1280px] text-center"
    >
      <h5 className="text-2xl text-center mt-20 mb-8">Ce que j'ai fait</h5>
      <h2 className="text-4xl text-center mb-16 font-oswald">
        Mes réalisations
      </h2>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Navigation, Pagination]}
        className="mySwiper bg-bonne-dark rounded-3xl"
      >
        {projectData.map((project: any) => (
          <SwiperSlide className="flex">
            <div className="img-container h-full">
              <img src={project.img_src[project.abbr]} alt={project.img_alt} />
            </div>
            <div className="text-container h-full text-dark-lava">
              <h2 className="text-3xl font-oswald mb-8 mt-4">{project.name}</h2>

              <p className="">{project.description}</p>

              <div className="flex flex-wrap justify-start my-8">
                {project.tags.map((tag: string) => (
                  <p className="px-2 py-1 my-2 mr-4 rounded-md text-sm border-solid border-[1px] border-copper text-copper">
                    {tag}
                  </p>
                ))}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Projets;
